import React, { Component } from 'react';
import Streams from './Streams'
import StreamViewer from './StreamViewer'
import {
    Route,
    HashRouter
  } from 'react-router-dom'
import { apiURL } from '../Utils'

class Content extends Component {

    constructor() {
        super();
        this.state = {
          streams : []
        }
        fetch(apiURL+"/streams")
        .then( r => r.json())
        .then( j => {
            this.setState({
                streams: j
            })
        })
    }

    getStream(name) {
        return this.state.streams.filter((s) => s.Name === name)[0]
    }

    render() {
        return(
            <HashRouter>
                <>
                    <Route
                        exact path='/'
                        render={() => <Streams streams={this.state.streams}></Streams>}
                    />
                    <Route
                        exact path='/:streamName'
                        render={({match}) => <StreamViewer streamName={match.params.streamName}></StreamViewer>}
                    />
                </>
            </HashRouter>
        )
    }
}

export default Content;
