import React, { Component } from 'react';
import {
    Link,
    withRouter
  } from 'react-router-dom';
import { getLastID, clearLastID, apiURL } from '../Utils'

class Stream extends Component {
    
    constructor() {
        super()
        this.state = {
            incoming : false
        }
    }

    componentDidMount() {
        this.checkForItems()
    }

    checkForItems() {
        fetch(apiURL+ "/stream/" + this.props.name +"/" + getLastID(this.props.name) + "/count")
        .then( r => r.text())
        .then( count => {
            this.setState(
                {incoming : 0 < Number(count)}
            )
            setTimeout(() => this.checkForItems(), 60000)
        })
    }

    handleClickDisabled = () => {
        let resetStream = window.confirm("No Items Left In Stream! Do you want to reset it?")
        if(resetStream) {
            clearLastID(this.props.name)
            this.props.history.push("/" + this.props.name)
        }
    }

    render() {
        if(!this.state.incoming) {
            return (
                <div className="stream" style={{background: "rgb(240, 240, 255)", color: "rgb(83, 83, 83)"}} onClick={this.handleClickDisabled}>
                    {this.props.name}
                </div>
            )
        }

        return(
            <Link to={"/" + this.props.name}>
                <div className="stream" style={{backgroundColor: this.props.color}}>
                    {this.props.name}
                </div>
            </Link>
        )
    }
}

export default withRouter(Stream)