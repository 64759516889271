export const apiURL = process.env.REACT_APP_API_URL

export function getLastID(name) {
    var idStr = localStorage.getItem(name + "_lastID");

    if (idStr === null) {
        return 0
    }
    else {
        return parseInt(idStr)
    }
}

export function setLastID(name, value) {
    localStorage.setItem(name + "_lastID", value)
}

export function clearLastID(name) {
    localStorage.removeItem(name + "_lastID")
}

export function getSiteName(url) {
    const siteStart = url.indexOf('://')
    const siteEnd = url.substr(siteStart+3, url.length-1).indexOf('/')

    const wwwIndex = url.substr(siteStart+3, siteEnd).indexOf('www')
    if(wwwIndex === -1) {
        return url.substr(siteStart+3, siteEnd)
    }

    return url.substr(siteStart+7, siteEnd-4)
}

export function getNumberBetweenInclusive(a,b) {
    return Math.floor(Math.random() * b) + a  
}

export function getRandomFYI() {
    const fyis = [
        "Hold the progress bar in the bottom-right corner to pause auto skipping. This will also unmute videos.",
        "On your PC or Mac use the Left and Right arrow keys to go to the next or previous story. Use the Down arrow key to open the story.",
        "A light-grey colored stream box means there are no new items available. Check back again soon!",
        "Try out different streams to branch out and learn new things!",
        "Add Storystreamer to your homescreen for app-like convenience!",
        "Would anyone you know be interested in Storystreamer? Be sure to let them know!"
    ]

    return fyis[getNumberBetweenInclusive(0, fyis.length-1)]
}