import React, { Component } from 'react';
import Stream from './Stream'

class Streams extends Component {

    render() {
        let streams
        if(this.props.streams) {
            streams = this.props.streams
        }
        else {
            streams = <span>No Streams Found</span> 
        }
        return(
            <>
                <div className="heading-helper">
                    <div className="heading">
                        <span className="logo">storystreamer</span>
                        <br></br>
                        <span className="tagline">The simplest way to stay updated.</span>
                    </div>
                </div>
                <div className="stream-holder">
                    <div className="stream-holder-holder">
                        {streams.map(s => <Stream name={s.name} color={s.color}></Stream>)}
                    </div>
                </div>
                
            </>
        )
    }
}
export default Streams;
