import React, { Component } from 'react';
import './App.css';
import Content from './Components/Content'

class App extends Component {

  render() {

    return (
      <>
        <Content ></Content>
      </>
    );
  }
}

export default App;
