import React, { Component } from 'react';
import {
    Redirect,
    Link,
  } from 'react-router-dom';
import YouTube from 'react-youtube';
import {getLastID, setLastID, apiURL, getSiteName, getRandomFYI} from '../Utils'

class Stream extends Component {

    constructor() {
        super()

        this.state = {
            items : [],
            current : null,
            close : false,
            onEnd : false,
            progressBar : 0,
            youTubePlayer: null,
            muted: true,
            millisecondsPerView: 5500,
            paused: false,
            scrollTopValue: 0
          }

        this.onKeyPressed =  this.onKeyPressed.bind(this)
        this.handleClickLeft =  this.handleClickLeft.bind(this)
        this.handleClickRight =  this.handleClickRight.bind(this)
        this.handleClickBottom =  this.handleClickBottom.bind(this)

    }

    componentDidMount() {
        fetch(apiURL+"/stream/" + this.props.streamName +"/" + getLastID(this.props.streamName))
        .then( r => r.json())
        .then( j => {
            if(j.items.length === 0) {
                this.setState({onEnd: true, progressBar : 0, millisecondsPerView: 7000, fyi:  getRandomFYI()})
                return
            }

            this.setState({
                items : j.items,
                current : j.items.length !== 0? 0 : null,
                color: j.color,
                millisecondsPerView: this.getNextMillisecondsPerView(j.items[0])
            })

            setTimeout(() => {
                this.incrementProgressBar()
            }, this.state.millisecondsPerView/100)
        })

        document.addEventListener("keydown", this.onKeyPressed);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyPressed);
    }

    incrementProgressBar() {
        if(!this.state.paused) {
            if(this.state.progressBar === 100)
                this.nextItem()

            var newValue = this.state.progressBar === 100? 0 : this.state.progressBar + 2;
            this.setState({progressBar : newValue})
        }

        setTimeout(() => {
            this.incrementProgressBar()
        }, this.state.millisecondsPerView/100)
    }

    onKeyPressed(e) {
        switch(e.keyCode) {
            case(38): {
                this.unMuteVideo()
                break;
            }
            case(37): {
                this.prevItem()
                break;
            }
            case(39): {
                this.nextItem()
                break;
            }
            case(40): this.openCurrentItem(); break;
            case(27): this.setState({close : true}); break;
            default:
                break;
        }
    }

    prevItem() {
        if(this.state.items.length === 0)
            return
        
        if(this.state.current === 0)
            return

        if(this.state.onEnd) {
            this.setState({onEnd: false})
        }

        let prevItem = this.state.items[this.state.current-1]

        if(0 > (this.state.current-1)) {
            this.setState({close : true})
        }

        this.setState({current : this.state.current - 1, progressBar : 0, millisecondsPerView: this.getNextMillisecondsPerView(prevItem), youTubePlayer: this.isYoutubeVideo(prevItem) ? this.state.youTubePlayer : null});

    }

    nextItem() {
        // If we saw end scree: close
        if(this.state.onEnd) {
            this.setState({close : true})
            return
        }

        // If no items: do nothing
        if(this.state.items.length === 0) {
            return
        }
        
        // Update the lastest seen Id for this stream
        if(this.state.items[this.state.current].id > getLastID(this.state.items[this.state.current].stream_name)) {
            setLastID(this.state.items[this.state.current].stream_name, this.state.items[this.state.current].id)
        }


        if(this.state.current+1 === this.state.items.length) {
            this.setState({current : this.state.current + 1, onEnd: true, progressBar : 0, millisecondsPerView: 7000, fyi:  getRandomFYI()})
            return
        }

        
        let nextItem = this.state.items[this.state.current+1]
        this.setState({current : this.state.current + 1, progressBar : 0, millisecondsPerView: this.getNextMillisecondsPerView(nextItem), youTubePlayer: this.isYoutubeVideo(nextItem) ? this.state.youTubePlayer : null})
    }

    getNextMillisecondsPerView = (nextItem) => {
        if(!nextItem)
            return 8000
        const searchString = 'https://www.youtube.com/watch?v='
        let found = nextItem.url.indexOf(searchString) !== -1

        if (!found) {
            return Math.max((nextItem.title.length * 110), 8000)
        }
        
        return (this.state.muted) ?
            30000 :
            60000
    }

    isYoutubeVideo(nextItem) {
        if(!nextItem)
            return false
        const searchString = 'https://www.youtube.com/watch?v='
        let found = nextItem.url.indexOf(searchString) !== -1
        return found
    }

    openCurrentItem() {
        window.open(this.state.items[this.state.current].url)
    }


    handleClickLeft(e) {
        this.prevItem()
    }


    handleClickRight(e) {
        this.nextItem()
    }

    handleClickBottom(e) {
        if(this.state.youTubePlayer) {
            this.state.youTubePlayer.pauseVideo()
        }

        this.openCurrentItem()
    }

    onPlayerReady = (event) => {
        this.setState({youTubePlayer: event.target, muted: true})
        //event.target.unMute();
        //event.target.mute();
        event.target.playVideo();
        //setTimeout(() => {
        //    event.target.unMute();
        //}, 10000)
    }

    onPlayerEnd = (event) => {
        this.nextItem()
    }

    unMuteVideo = () => {
        if(!this.state.youTubePlayer) {
            return
        }

        this.state.youTubePlayer.unMute();
        this.setState({muted: false})
        this.setState({millisecondsPerView: 60000, progressBar: 0})
    }

    unMuteVideoForAutoplayStop = () => {
        if(!this.state.youTubePlayer) {
            return
        }

        this.state.youTubePlayer.unMute();
        this.setState({muted: false})
    }

    muteVideo = () => {
        if(!this.state.youTubePlayer) {
            return
        }

        this.state.youTubePlayer.mute();
        this.setState({muted: true})
        this.setState({millisecondsPerView: 30000})
    }

    scrollContentDown = () => {

        console.log(this.state.scrollTopValue)

        this.setState({scrollTopValue: this.state.scrollTopValue + 0.1, autoScrollHandle: setTimeout(() => {
            this.scrollContentDown()
        }, 1)})

    }

    pauseAutoplay = () => {
        this.setState({paused: true})
        this.unMuteVideoForAutoplayStop()

        this.setState({scrollTopValue: 0, autoScrollHandle: setTimeout(() => {
            this.scrollContentDown()
        }, 6000)})
    }

    startAutoplay = () => {
        this.setState({scrollTopValue: 0})

        clearTimeout(this.state.autoScrollHandle)

        if(this.state.skipNext) {
            this.setState({skipNext: false, progressBar: 100})
        }

        this.setState({paused: false})
        this.muteVideo()
    }

    setSkipNext = () => {
        alert("hello!")
        this.setState({skipNext: true})
    }

    unsetSkipNext = () => {
        alert("bye!")
        this.setState({skipNext: false})
    }

    render() {
        if (this.state.close) {
            //window.clearTimeout(this.itemIncrementor)
            return <Redirect to='/'/>;
        }
        
        if(this.state.onEnd) {
            return(
                <>
                    <div active className="stream-viewer" style={{backgroundColor: "rgb(240, 240, 255)"}}>
                        
                        <div className="center-viewer">
                            <div className="logo">storystreamer</div>
                            <div style={{color: this.state.color}} className="tagline end-screen">All caught up with {this.props.streamName}!</div>
                            <div className="tip-holder">
                            <b>Tip:</b> {this.state.fyi}
                            </div>
                        </div>
                    </div>

                    <div className="stream-viewer-overlay">
                        <div className="stream-viewer-overlay-top">
                        </div>
                        <div className="stream-viewer-overlay-leftright">
                            <div className="stream-viewer-overlay-left" onClick={this.handleClickLeft}>
                                <div className="stream-viewer-overlay-left-button">Prev</div>
                            </div>
                            <div className="stream-viewer-overlay-right" onClick={this.handleClickRight}>
                                <div className="stream-viewer-overlay-right-button">Next</div>
                            </div>
                        </div>
                        <div className="stream-viewer-overlay-bottom">
                            <div className="stream-viewer-close">
                                <Link to="/" style={{textDecoration: "none"}}>
                                    <span className="stream-viewer-close-button">➜</span>
                                </Link>
                            </div>
                            <div style={{flex: 1, textAlign: "center"}}>
                            </div>
                            <div style={{flex: 0, textAlign: "center"}}>
                                <div className="progress-thing" onTouchStart={this.pauseAutoplay} onMouseDown={this.pauseAutoplay} onTouchEnd={this.startAutoplay} onMouseUp={this.startAutoplay}>
                                    <div className="progress-bar" style={{width: this.state.progressBar+"%"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (this.state.items.length !== 0) {
            let progressBarWidth = String(Math.floor((this.state.current / this.state.items.length) * 100)) + "%"

            let title = (this.state.items[this.state.current].title.length < 160) ?
                this.state.items[this.state.current].title :
                (this.state.items[this.state.current].title.substr(0, 160) + "...")

                const searchString = 'https://www.youtube.com/watch?v='
                var found = this.state.items[this.state.current].url.indexOf(searchString)

            let youtube = (found !== -1) ?
            <>
                <div className="video-background">
                    <YouTube id="youtube-player" videoId={this.state.items[this.state.current].url.substr(searchString.length)} opts={{playerVars: {autoplay: 1, showinfo: 0, start: 60, playsinline: 1, enablejsapi: 1, controls: 0, mute: 1}}} onReady={this.onPlayerReady} onEnd={this.onPlayerEnd}/> 
                </div>
            </> :
            null

            let sourceStr = getSiteName(this.state.items[this.state.current].url)

            let muter = (this.state.muted && this.state.youTubePlayer && this.isYoutubeVideo(this.state.items[this.state.current])) ? <>
                    <div class="unmuter" onClick={this.unMuteVideo}>Unmute</div>
            </> :
            null

            let streamName = this.state.items[this.state.current].stream_name

            let content = this.state.items[this.state.current].content

            return(
                <>  
                    <div style={{display:"none"}}>
                        {this.state.items.map(i => <img alt="preload-img" src={i.image}></img>)}
                    </div>
                    <div active className="stream-viewer" tabIndex="0" style={{backgroundColor: this.state.color, backgroundImage: "url("+this.state.items[this.state.current].image +")"}}>
                        
                        <div className="overall-progress-bar-holder">
                            <div className="overall-progress-bar" style={{width: progressBarWidth}}></div>
                        </div>
                        {youtube}
                        <div
                            className={(youtube? "stream-box shifted-down" : "stream-box") + ((this.state.paused) ? " paused-box" : "")}
                            style=
                                {{opacity: (this.state.paused && (this.state.youTubePlayer || content === "photo")) ? 0 : 1}}>
                            <div style={{paddingLeft: 10, paddingRight: 10}}>
                                {title}
                                <div className="source-info">
                                    {sourceStr}
                                </div>
                            </div>
                            <div style={{textAlign: "center", marginTop: -this.state.scrollTopValue}}>
                                <div scrollTop={this.state.scrollTopValue} id="text-content" style={{display: (this.state.paused && content && content !== "photo") ? "inline-block" : "none"}} class="content-holder"
                                    dangerouslySetInnerHTML={{__html: content}}
                                ></div>
                            </div>
                        </div>
                    </div>
                    
                    {/*<div className="stream-viewer-overlay" style={{opacity: (this.state.paused) ? 1 : 0}}>
                        <div className="stream-viewer-overlay-top">
                        </div>
                        <div className="stream-viewer-overlay-leftright">
                            <div className="stream-viewer-overlay-left">
                            </div>
                            <div className="stream-viewer-overlay-right">
                                <div className="stream-viewer-overlay-right-button">
                                    <div class="overlay-button" onTouchStart={this.setSkipNext} onTouchEnd={this.unsetSkipNext}>
                                        Next
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stream-viewer-overlay-bottom">
                            <div className="stream-viewer-close">
                            </div>
                            <div style={{flex: 1, textAlign: "center"}}>
                                <div class="overlay-button" style={{opacity:0.4}}>
                                    Open Full Story
                                </div>
                            </div>
                            <div style={{flex: 0, textAlign: "center"}}>
                            </div>
                        </div>
                </div>*/}

                    <div className="stream-viewer-overlay" style={{opacity: (this.state.paused) ? 0 : 1}}>
                        <div className="stream-viewer-overlay-top">
                            <div>
                                <div className="stream-name-holder">
                                    #{streamName}
                                </div>
                            </div>
                            <div className="muter-holder">
                                {muter}
                            </div>
                        </div>
                        <div className="stream-viewer-overlay-leftright">
                            <div className="stream-viewer-overlay-left" onClick={this.handleClickLeft}>
                                <div className="stream-viewer-overlay-left-button">Prev</div>
                            </div>
                            <div className="stream-viewer-overlay-right" onClick={this.handleClickRight}>
                                <div className="stream-viewer-overlay-right-button">Next</div>
                            </div>
                        </div>
                        <div className="stream-viewer-overlay-bottom">
                            <div className="stream-viewer-close">
                                <Link to="/" style={{textDecoration: "none"}}>
                                    <span className="stream-viewer-close-button">➜</span>
                                </Link>
                            </div>
                            <div style={{flex: 1, textAlign: "center"}}>
                                <div className="stream-viewer-overlay-bottom-button" onClick={this.handleClickBottom}>{(content === "photo") ? "Open Photo" : this.state.youTubePlayer ? "Full Video" : "Open Story"}</div>
                            </div>
                            <div style={{flex: 0, textAlign: "center"}}>
                                <div className="progress-thing" onTouchStart={this.pauseAutoplay} onMouseDown={this.pauseAutoplay} onTouchEnd={this.startAutoplay} onMouseUp={this.startAutoplay}>
                                    <div style={{float:"left", width: "100%", marginTop: 13, color: "grey"}}>Hold Here</div>
                                    <div className="progress-bar" style={{width: this.state.progressBar+"%"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else {
            return(
                <div active className="stream-viewer" style={{backgroundColor: this.state.color}}>
                    <div className="stream-box">
                    </div>

                </div>
            )
        }
        
    }
}

export default Stream;
